import React from 'react';
import styled from 'styled-components';
import { Maybe, PropertyCardFragment } from '../../../gatsby-graphql';
import FeaturedPropertyCard from '../FeaturedPropertyCard/FeaturedPropertyCard';
import PropertyCard from '../PropertyCard/PropertyCard';
import { ImageResolver } from '../PropertyCardMediaSlider/PropertyCardMediaSlider';

interface IPropertiesListingProps {
  properties?: Maybe<PropertyCardFragment>[] | null;
  listingType?: 'grid' | 'list';
  internal?: boolean;
  imageResolver: ImageResolver;
}

const PropertiesListing: React.FC<IPropertiesListingProps> = ({
  properties,
  internal,
  listingType,
  imageResolver,
}) => (
  <div>
    <FeaturedPropertyCard
      property={properties[0] as PropertyCardFragment}
      internal={internal}
    />
    <Listing className={listingType}>
      {properties
        ?.slice(1, properties?.length)
        .map((property) =>
          listingType === 'grid' ? (
            <PropertyCard
              imageResolver={imageResolver}
              key={property?.id as string}
              {...(property as PropertyCardFragment)}
            />
          ) : (
            <FeaturedPropertyCard
              property={property as PropertyCardFragment}
              internal={internal}
            />
          )
        )}
    </Listing>
  </div>
);

const Listing = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  ${(props) => props.theme.mq.tablet} {
    &.grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  ${(props) => props.theme.mq.desktop} {
    gap: 34px;
    &.grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
`;

PropertiesListing.defaultProps = {
  listingType: 'grid',
};

export default PropertiesListing;
