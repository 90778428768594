import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { PropertyCardFragment } from '../../../gatsby-graphql';
import PropertyInfoItem from '../PropertyInfoItem/PropertyInfoItem';
import { BedIcon, BathIcon, BlueprintIcon } from '../../icons';
import PropertyCardMediaSlider from '../PropertyCardMediaSlider/PropertyCardMediaSlider';
import { createPropertyLink, formatPrice } from '../../utils';

interface IFeaturedPropertyCardProps {
  property: PropertyCardFragment;
  internal?: boolean;
}

const FeaturedPropertyCard: React.FC<IFeaturedPropertyCardProps> = ({
  property: {
    name,
    price,
    propertyLocation,
    images,
    bedroomsCount,
    bathroomsCount,
    internalArea,
    shortDescription,
    slug,
    badge,
    operationType,
    propertyType,
  },
  internal,
}) => (
  <Container
    to={createPropertyLink(
      slug as string,
      operationType?.slug as string,
      propertyType?.slug as string
    )}
    className={internal ? 'internal' : ''}
  >
    <MediaContainer>
      <PropertyCardMediaSlider
        imageResolver="featured"
        images={images?.slice(0, 4)}
        badge={badge}
      />
      <InactiveMediaContainer>
        {images?.slice(1, 4)?.map((image) => (
          <SmallGatsbyImage
            key={image?.id}
            image={image?.featuredSmall}
            alt={image?.description as string}
          />
        ))}
      </InactiveMediaContainer>
    </MediaContainer>
    <InfoContainer>
      <h3>{name}</h3>
      <span>{formatPrice(price as number)}</span>
      <PropertyLocation>{propertyLocation?.name}</PropertyLocation>
      <PropertyInfoItems>
        <PropertyInfoItem
          label="Area"
          value={internalArea}
          renderIcon={() => <BlueprintIcon />}
          internal={internal}
        />
        <PropertyInfoItem
          label="Dormitorios"
          value={bedroomsCount}
          renderIcon={() => <BedIcon />}
          internal={internal}
        />
        <PropertyInfoItem
          label="Baños"
          value={bathroomsCount}
          renderIcon={() => <BathIcon />}
          internal={internal}
        />
      </PropertyInfoItems>
      <PropertyDescription>
        <p>{shortDescription}</p>
      </PropertyDescription>
    </InfoContainer>
  </Container>
);

const Container = styled(Link)`
  display: grid;
  align-items: flex-start;
  border-radius: 8px;
  margin-bottom: 40px;
  text-decoration: none;
  ${(props) => props.theme.mq.desktop} {
    grid-template-columns: 7fr 5fr;
  }
`;

const MediaContainer = styled.div`
  ${(props) => props.theme.mq.desktop} {
    padding-right: 34px;
  }
`;

const InactiveMediaContainer = styled.div`
  display: none;
  ${(props) => props.theme.mq.desktop} {
    display: grid;
    column-gap: 14px;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 14px;
  }
`;

const SmallGatsbyImage = styled(GatsbyImage)`
  border-radius: 8px;
`;

const InfoContainer = styled.div`
  padding: 24px 16px 24px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.1);
  ${(props) => props.theme.mq.tablet} {
    padding: 40px 34px 40px;
    border-radius: 8px;
    .internal & {
      padding: 34px 24px 34px;
    }
  }
  h3 {
    font-size: 22px;
    line-height: 27px;
    color: ${(props) => props.theme.colors.dark.primary};
    font-weight: bold;
    margin: 0 0 9px;
    ${(props) => props.theme.mq.tablet} {
      font-size: 28px;
      line-height: 33px;
      .internal & {
        font-size: 18px;
        line-height: 21px;
      }
    }
  }
  h3 + span:first-of-type {
    font-size: 22px;
    line-height: 27px;
    color: ${(props) => props.theme.colors.dark.primary};
    font-weight: bold;
    font-family: ${(props) => props.theme.fonts.helvetica};
    ${(props) => props.theme.mq.tablet} {
      font-size: 28px;
      line-height: 33px;
      .internal & {
        font-size: 18px;
        line-height: 21px;
      }
    }
  }
`;

const PropertyLocation = styled.address`
  display: block;
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  margin: 11px 0 27px;
  color: ${(props) => props.theme.colors.dark.primary};
  ${(props) => props.theme.mq.tablet} {
    font-size: 18px;
    line-height: 21px;
    .internal & {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 22px;
    }
  }
`;

const PropertyInfoItems = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
`;

const PropertyDescription = styled.div`
  color: ${(props) => props.theme.colors.dark.primary};
  p {
    font-size: 14px;
    line-height: 22px;
    margin: 25px 0 10px;
    letter-spacing: 0.2px;
    .internal & {
      margin-top: 18px;
      line-height: 19px;
    }
  }
`;

export default React.memo(FeaturedPropertyCard);
