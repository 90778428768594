import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { PropertyCardFragment } from '../../../gatsby-graphql';
import PropertyCardMediaSlider, {
  ImageResolver,
} from '../PropertyCardMediaSlider/PropertyCardMediaSlider';
import { createPropertyLink, formatPrice } from '../../utils';

interface IPropertyCardProps {
  imageResolver: ImageResolver;
}

const PropertyCard: React.FC<IPropertyCardProps & PropertyCardFragment> = ({
  images,
  slug,
  badge,
  name,
  price,
  bedroomsCount,
  bathroomsCount,
  internalArea,
  propertyLocation,
  operationType,
  propertyType,
  imageResolver,
}) => (
  <Container
    to={createPropertyLink(
      slug as string,
      operationType?.slug as string,
      propertyType?.slug as string
    )}
  >
    <PropertyCardMediaSlider
      imageResolver={imageResolver}
      images={images?.slice(0, 4)}
      badge={badge}
    />
    <InfoContainer>
      <h3>{name}</h3>
      <span>{formatPrice(price as number)}</span>
      <span>
        {Number(bedroomsCount) > 0 && (
          <>
            <span>{bedroomsCount}</span>
            <span> Dormitorios, </span>
          </>
        )}
        {Number(bathroomsCount) > 0 && (
          <>
            <span>{bathroomsCount}</span>
            <span> Baños, </span>
          </>
        )}
        <span>{internalArea}</span>
        <sup>m2</sup>
      </span>
      <PropertyLocation>{propertyLocation?.name}</PropertyLocation>
    </InfoContainer>
  </Container>
);

const Container = styled(Link)`
  border-radius: 8px;
  box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.1);
  background-color: ${(props) => props.theme.colors.lightSurface};
  text-decoration: none;
  color: ${(props) => props.theme.colors.dark.primary};
`;

const InfoContainer = styled.div`
  padding: 18px 16px 24px;
  h3 {
    font-size: 16px;
    line-height: 1.5;
    font-weight: bold;
    margin: 0 0 10px;
  }
  & > span:first-of-type {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 18px;
    font-weight: bold;
    font-family: ${(props) => props.theme.fonts.helvetica};
  }
  & > span:last-of-type {
    display: block;
    margin-bottom: 6px;
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    span {
      font-family: ${(props) => props.theme.fonts.helvetica};
    }
  }
`;

const PropertyLocation = styled.address`
  display: block;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
`;

export default React.memo(PropertyCard);
