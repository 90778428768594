import React from 'react';
import styled from 'styled-components';

interface IPropertyInfoItemProps {
  label?: string | null;
  value?: number | null;
  renderIcon(): React.ReactNode;
  internal?: boolean;
}

const PropertyInfoItem: React.FC<IPropertyInfoItemProps> = ({
  label,
  value,
  renderIcon,
  internal,
}) => (
  <Container internal={internal}>
    <span>{label}</span>
    <div>
      {renderIcon()}
      <span>{value}</span>
    </div>
  </Container>
);

const Container = styled.div<{ internal?: boolean }>`
  & > span:first-of-type {
    display: block;
    font-size: ${(props) => (props.internal ? '14px' : '16px')};
    line-height: ${(props) => (props.internal ? '16px' : '19px')};
    font-weight: normal;
    color: ${(props) => props.theme.colors.dark.primary};
    margin-bottom: 8px;
  }
  div {
    display: flex;
    align-items: baseline;
    span {
      font-size: ${(props) => (props.internal ? '24px' : '34px')};
      line-height: ${(props) => (props.internal ? '29px' : '36px')};
      color: ${(props) => props.theme.colors.secondary};
      font-family: ${(props) => props.theme.fonts.helvetica};
      margin-right: ${(props) => (props.internal ? '2px' : '8px')};
      display: block;
    }
    svg {
      margin-right: ${(props) => (props.internal ? '0' : '0.5rem')};
      height: ${(props) => (props.internal ? '20px' : '24px')};
      g,
      path {
        fill: ${(props) => props.theme.colors.secondary};
      }
    }
  }
`;

export default React.memo(PropertyInfoItem);
